import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { createClient } from "graphql-ws";
import { config } from "../config";
import { getAuthToken, getSession, signOut } from "./amplify";
import { getAssumeRole, getCurrentAccountId } from "./auth";
import { Permission } from "../dto/AccountUser";
import { generateRedirectToPath } from "../utils";

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.api.wsURI,
    lazy: true,
    connectionParams: async () => {
      const params = { headers: {} as any };
      const token = await getAuthToken().catch(() => null);
      if (token) {
        params.headers.Authorization = `Bearer ${token}`;
        const accountId = getCurrentAccountId();
        const assumeRole = getAssumeRole();
        if (assumeRole) {
          params.headers["x-assume-role"] = assumeRole;
        } else if (accountId) {
          params.headers["x-account-id"] = accountId;
        }
      }
      return params;
    },
  })
);

const httpLinkAuth = createUploadLink({
  uri: config.api.httpURI,
  fetch: async (uri, options: any) => {

    const session = await getSession().catch(() => null);

    let invalidSession = false;
    if (!session || !session.isValid()) {
      invalidSession = true;
    } else {
      const accessToken = session.getAccessToken();
      if (accessToken.getExpiration() * 1000 <= new Date().getTime()) {
        invalidSession = true;
      }
    }

    if (invalidSession && getCurrentAccountId(false)) {
      if (!(window as any).PromptAuthenticationListenerSetup) {
        signOut().then(() => {
          location.replace(`/signin${generateRedirectToPath({
            withTempPassword: false,
          })}`);
        });
      } else {
        document.dispatchEvent(
          new Event("PromptAuthentication", {
            bubbles: true,
          })
        );
  
        await new Promise((resolve) => {
          const handler = function () {
            document.removeEventListener(
              "PromptAuthenticationIgnored",
              handler
            );
            document.removeEventListener(
              "PromptAuthenticationAuthenticated",
              handler
            );
  
            resolve(undefined);
          };
  
          document.addEventListener("PromptAuthenticationIgnored", handler);
          document.addEventListener("PromptAuthenticationAuthenticated", handler);
        });
      }
    }

    const token = await getSession().catch(() => null).then((s) => s?.getAccessToken().getJwtToken());
    
    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
      const accountId = getCurrentAccountId();
      const assumeRole = getAssumeRole();
      if (assumeRole) {
        options.headers["x-assume-role"] = assumeRole;
      } else if (accountId) {
        options.headers["x-account-id"] = accountId;
      }
    }
    return fetch(uri, options);
  },
});

const omitVariable = (obj: any) => {
  const result: any = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    if (obj[key] instanceof Date) {
      result[key] = (obj[key] as Date).toLocaleDateString("en-US");
    } else if (
      !!obj[key] &&
      ![
        "proofOfAddress",
        "articlesOfAssociation",
        "certificateOfIncorporation",
        "certificateOfAmalgamation",
      ].includes(key) &&
      !(obj[key] instanceof File) &&
      typeof obj[key] === "object"
    ) {
      result[key] = omitVariable(obj[key]);
    } else if (
      ![
        "__typename",
        "proofOfAddress",
        "articlesOfAssociation",
        "certificateOfIncorporation",
        "certificateOfAmalgamation",
      ].includes(key) ||
      obj[key] instanceof File
    ) {
      result[key] = obj[key];
    }
  });

  return result;
};

const cleanVariables = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitVariable(operation.variables);
  }
  return forward(operation)?.map((data) => {
    return data;
  });
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as any;
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  cleanVariables.concat(httpLinkAuth)
);

export const client = new ApolloClient({
  link: ApolloLink.from([link]),
  cache: new InMemoryCache({
    typePolicies: {
      AccountUser: {
        fields: {
          canAssumeRole: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.AssumeRole);
            },
          },
          canEditAccount: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.EditAccount);
            },
          },
          canEditAccounts: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.EditAccounts);
            },
          },
          canEditOffers: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.EditOffers);
            },
          },
          canInitiateInvestment: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.InitiateInvestment);
            },
          },
          canEditAccountRiskRating: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.CanEditAccountRiskRating);
            },
          },
          canEditTransactions: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.EditTransactions);
            },
          },
          canViewTransactions: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.ViewTransactions);
            },
          },
          canViewAuditLogs: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.ViewAuditLogs);
            },
          },
          canEditPermissions: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.EditPermissions);
            },
          },
          canViewAccounts: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.ViewAccounts);
            },
          },
          canViewInvestors: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.ViewAccounts);
            },
          },
          canExportAccountDetails: {
            read(_, { readField }) {
              const permissions = (readField("permissions") || []) as Array<string>;
              return permissions.includes(Permission.CanExportAccountDetails);
            },
          },
        },
      },
      Offer: {
        fields: {
          completionPercentage: {
            read(_, { readField }) {
              const totalContributionAmount = (readField("totalContributionAmount") ||
                0) as number;
              const totalRaisedOutsidePlatform = (readField(
                "totalRaisedOutsidePlatform"
              ) || 0) as number;
              const offerSize = (readField("offerSize") || 1) as number;
              return (
                ((totalContributionAmount + totalRaisedOutsidePlatform) / offerSize) * 100
              );
            },
          },
          invested: {
            read(_, { readField }) {
              return !!(readField("myInvestment") as any)?.contributionAmount;
            },
          },
        },
      },
    },
  }),
  connectToDevTools: true,
});
